.darkTheme {
  @mixin colorDarkOverride;
}

.lightTheme {
  @mixin colorDefinitions;
}

.thinScrollbar {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: rem(3);
    height: rem(3);
    background-color: clr(g-90/30);
  }

  &::-webkit-scrollbar-thumb {
    background-color: clr(p-70);
  }
}

.wrapper {
  --wrapper-max-width: 100vw;
  --wrapper-inner-width: calc(var(--wrapper-max-width) - rem(30));

  max-width: var(--wrapper-max-width);
  box-sizing: border-box;
  padding-left: rem(15);
  padding-right: rem(15);

  .nc {
    margin-top: rem(15);
    margin-bottom: rem(15);

    @media bp(sm) {
      margin-top: rem(20);
      margin-bottom: rem(20);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .mobileFull {
    width: min(100vw, var(--wrapper-max-width));
    margin-left: var(--outside-mobile-frame, rem(-15));
  }

  @media bp(sm) {
    contain: none;

    .mobileFull {
      width: auto;
      margin-left: inherit;
    }
  }

  @media bp(lg) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    --wrapper-max-width: rem(1320);
    --wrapper-inner-width: min(calc(var(--wrapper-max-width) - rem(30)), calc(100vw - rem(30)));
  }
}

.wrapperWide {
  @media bp(xl) {
    --wrapper-max-width: rem(1760);
  }
}

.pageTitle {
  font-weight: bold;
  font-size: rem(30);
  line-height: rem(38);

  @media bp(lg) {
    font-size: rem(60);
    line-height: rem(70);
  }
}

.pageTitleMargins {
  margin-top: rem(30);
  margin-bottom: rem(20);

  @media bp(lg) {
    margin-top: rem(70);
    margin-bottom: rem(20);
  }
}

.pageBodyTitle {
  font-weight: bold;
  font-size: rem(22);
  line-height: rem(26);

  @media bp(lg) {
    font-size: rem(32);
    line-height: rem(40);
  }
}

.sidebarEl {
  position: sticky;
  top: rem(130);
}

.leaderboard {
  @media bp(sm) {
    margin-top: rem(50);
  }
}

/* check TakeoverScript */
.takeoverOn {
  .main {
    @media screen and (min-width: 1070px) {
      /* max-width: 1070px !important; */
      width: min(1070px, 100vw) !important;
      margin: 0 auto !important;
    }
  }

  .wrapper {
    background-color: clr(w);
  }
}

.anchor {
  position: absolute;
  visibility: none;
  width: 0;
  height: 0;
  overflow: hidden;
  margin-top: rem(-100);

  @media bp(xl) {
    margin-top: rem(-120);
  }
}

.cutoffText {
  --max-lines: var(--cutoff-max-lines, 2);
  --line-height: var(--cutoff-line-height, rem(24));

  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
  white-space: pre-line;
  max-height: calc(var(--max-lines) * rem(1) * var(--line-height));
  line-height: var(--line-height);
  overflow: hidden;
  position: relative;
}
