/* base styles */

/*
fix: https://bugs.chromium.org/p/chromium/issues/detail?id=1161709
*/
@media print {
  * {
    row-gap: unset !important;
    column-gap: unset !important;
  }
}

:root {
  font-family: font(sans);
  overflow-x: hidden;

  @mixin colorDefinitions;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

svg {
  fill: currentColor;
}

picture,
img {
  display: block;
}

img {
  object-fit: cover;
  background-color: clr(g-90);
}

picture {
  position: relative;
  border-radius: rem(2);
}

main {
  flex: 1;
}

body {
  position: relative;
  color: clr(b);
  background-color: clr(w);
}

input,
textarea,
select {
  font-weight: bold;
  appearance: none;
  resize: none;

  &::placeholder {
    color: clr(cb/30);
  }
}

a {
  display: inline-block;

  * {
    /* to handle click events correctly */
    pointer-events: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 rem(30) clr(g-90) inset;
  -webkit-text-fill-color: clr(b);
}

/* fonts */
@font-face {
  font-family: app-sans;
  src: path(sans-mi-woff2) format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: app-sans;
  src: path(sans-ri-woff2) format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: app-sans;
  src: path(sans-r-woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: app-sans;
  src: path(sans-m-woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: app-sans;
  src: path(sans-sb-woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: app-sans;
  src: path(sans-b-woff2) format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: app-sans;
  src: path(sans-bi-woff2) format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: app-narrow;
  src: path(narrow-woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

button,
input,
optgroup,
select,
a,
hr,
textarea {
  color: clr(b);
}
