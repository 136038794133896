@media screen and (max-width: 480px) {
  .auth0-lock.auth0-lock.auth0-lock-opened {
    position: fixed !important;
  }
}

html.auth0-lock-html {
  overflow: hidden;
}

.auth0-lock.auth0-lock {
  z-index: 1000001 !important;
}
